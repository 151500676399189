.react-datepicker__input-container {
    input {
        font-weight: 300;
        font-size: 14px;
        height: 32px;
        color: white;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        background-color: transparent;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        outline: none; } }

.react-datepicker {
    * {
        outline: none; }

    font-size: 100%;
    background-color: #252525;
    color: white;
    border: 1px white solid;

    .react-datepicker__header {
        background-color: #252525;
        color: white; }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: white; }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range, {
        background-color: #00a8ff; }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        color: black;
        background-color: #C4C4C4; }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: white;
        font-size: 100%;
        margin-top: 4px;
        margin-bottom: 4px; }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2.4rem;
        line-height: 2.4rem; }

    .react-datepicker__day--outside-month {
        color: #848484; }

    .react-datepicker__navigation {
        border-width: 0.5rem;
        top: 16px; } }
