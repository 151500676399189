* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html,
body,
#root {
    width: 100%;
    height: 100%; }

body {
    background-color: #000;
    color: #fff;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; }


